<template>
  <div class="index_wrap">
    <h1 class="h_none">商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 90px"></div>
    <div class="about_top">
      <div class="top">
        <span>关于我们</span>
      </div>
      <ul>
        <li>
          北京蓝灯鱼智能科技有限公司（以下简称「蓝灯鱼」），成立于2017年，致力于通过对人工智能和大数据的探索和研究，
          以数字科技的力量赋能知识产权行业。
        </li>
        <li>
          「蓝灯鱼」的产品和服务面向全球拥有和使用知识产权的用户，基于全球专利、商标大数据，依据大数据挖掘、人工智能
          深度学习算法和用户行业特性，提供技术先进、易用便捷的知识产权增值及效率产品。
        </li>
      </ul>
    </div>
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
        <div class="about_banner_title">
          <p><span style="font-size: 72px;font-weight: 600">我们</span>让智慧知产未来可期! The future is in sight!</p>
        </div>
        <div class="about_banner_cen">
          <span class="banner_title">
            <i>「蓝灯鱼」提供产品和服务包括：</i>
          </span>
          商标图形查询、专利翻译、专利检索、商标管理系统、专利管理系统、
          专利年费管理系统、商标自动提交官申、知识产权智慧园区管理、代理机构EHR管理系统、专利检索报告
          自动生成工具等知识产权系列软件产品。<br/>「蓝灯鱼」将会一直秉承“数据赋能知识产权、智能驱动创新发
          展”的发展理念，携手所有伙伴和用户，共同打造知识产权数字智能生态系统，助力产业创新发展。
        </div>
    </div>

    <div class="step">
      <div class="top" style="margin-bottom: 20px">
        <span>发展历程</span>
        <div class="littleTitle">THE DEVELOPMENT COURSE</div>
      </div>

      <div class="mainContent">
        <svg width="1234px" height="1378px" viewBox="0 0 1234 1278" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>发展历程</title>
          <defs>
            <linearGradient x1="73.3973101%" y1="50%" x2="26.6026899%" y2="100%" id="linearGradient-1">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="74.7458969%" y1="50%" x2="25.2541031%" y2="100%" id="linearGradient-2">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="80.977489%" y1="50%" x2="19.022511%" y2="100%" id="linearGradient-3">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="10.8350915%" y1="27.3458993%" x2="104.000387%" y2="56.9743757%" id="linearGradient-4">
              <stop stop-color="#BEC5F1" offset="0%"></stop>
              <stop stop-color="#2C3DC2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="3.86299586%" y1="60.4652597%" x2="100%" y2="44.4930461%" id="linearGradient-5">
              <stop stop-color="#A4AFEC" offset="0%"></stop>
              <stop stop-color="#2C3DC2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="81.9877555%" y1="50%" x2="26.6026899%" y2="100%" id="linearGradient-6">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="83.8314831%" y1="50%" x2="25.2541031%" y2="100%" id="linearGradient-7">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="57.6893502%" y1="50%" x2="42.3106498%" y2="100%" id="linearGradient-8">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="74.6511759%" y1="50%" x2="25.3488241%" y2="100%" id="linearGradient-9">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="73.3973101%" y1="50%" x2="26.6026899%" y2="100%" id="linearGradient-10">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="74.7458969%" y1="50%" x2="25.2541031%" y2="100%" id="linearGradient-11">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="57.6893502%" y1="50%" x2="42.3106498%" y2="100%" id="linearGradient-12">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="74.6511759%" y1="50%" x2="25.3488241%" y2="100%" id="linearGradient-13">
              <stop stop-color="#2B2AC1" offset="0%"></stop>
              <stop stop-color="#708CF3" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="76.091063%" y1="50%" x2="23.908937%" y2="100%" id="linearGradient-14">
              <stop stop-color="#708CF3" offset="0%"></stop>
              <stop stop-color="#2B2AC1" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="关于我们备份" transform="translate(-360.000000, -1204.000000)">
              <g id="发展历程" transform="translate(360.000000, 1205.000000)">
                <g id="2024" transform="translate(38.938000, 13.336000)" fill-rule="nonzero">
                  <path d="M9.88,0 C6.954,0 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,0 9.88,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                  <path d="M32.262,0 C29.07,0 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,0 32.262,0 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-2)"></path>
                  <path d="M55.48,0 C52.554,0 50.198,0.988 48.412,2.964 C46.664,4.826 45.79,7.258 45.752,10.298 L50.198,10.298 C50.274,8.17 50.73,6.536 51.566,5.472 C52.402,4.332 53.656,3.762 55.328,3.762 C56.886,3.762 58.064,4.104 58.862,4.826 C59.622,5.548 60.04,6.65 60.04,8.132 C60.04,9.652 59.432,11.096 58.254,12.426 C57.494,13.224 56.278,14.25 54.53,15.466 C51.376,17.632 49.286,19.304 48.298,20.444 C46.474,22.496 45.6,24.89 45.6,27.664 L64.524,27.664 L64.524,23.75 L51.148,23.75 C51.756,22.382 53.428,20.786 56.202,18.962 C58.862,17.176 60.762,15.732 61.826,14.592 C63.574,12.654 64.486,10.526 64.486,8.17 C64.486,5.738 63.65,3.8 61.978,2.28 C60.268,0.76 58.102,0 55.48,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                  <path d="M80.446,0.532 L67.184,17.632 L67.184,21.85 L80.294,21.85 L80.294,27.664 L84.512,27.664 L84.512,21.85 L88.54,21.85 L88.54,18.316 L84.512,18.316 L84.512,0.532 L80.446,0.532 Z M80.18,6.156 L80.294,6.156 L80.294,18.316 L70.794,18.316 L80.18,6.156 Z" id="形状" fill="url(#linearGradient-3)"></path>
                </g>
                <g id="编组-6">
                  <g id="编组-5" transform="translate(0.000000, 31.500000)">
                    <g id="编组-4" transform="translate(59.000000, 0.000000)" stroke-width="3.5">
                      <path d="M85.5366787,0 L906.175,0 C1023.39167,0 1082,41.1055364 1082,123.316609 C1082,205.527682 1023.39167,247.114548 906.175,248.077207 L156.89,248.077207 C52.2966667,247.114548 0,286.005969 0,364.751469 C0,443.49697 52.2966667,481.425732 156.89,478.537755 L906.175,478.537755 C1023.39167,478.537755 1082,521.08728 1082,606.186329 C1082,691.285379 1023.39167,731.909586 906.175,728.05895 L164.35443,728.05895" id="路径-3" stroke="url(#linearGradient-4)"></path>
                      <path d="M527.303797,728.308991 L906.175,728.308991 C1023.39167,728.308991 1082,769.414528 1082,851.625601 C1082,933.836673 1023.39167,975.423539 906.175,976.386198 L156.89,976.386198 C52.2966667,975.423539 0,1014.31496 0,1093.06046 C0,1171.80596 52.2966667,1209.73472 156.89,1206.84675 L926,1206.84675" id="路径-3备份" stroke="url(#linearGradient-5)" transform="translate(541.000000, 967.654496) scale(-1, 1) translate(-541.000000, -967.654496) "></path>
                    </g>
                    <rect id="矩形" fill="#F1F3FA" x="1067" y="95.5" width="161" height="61"></rect>
                    <rect id="矩形备份-7" fill="#F1F3FA" x="0" y="339.5" width="161" height="61"></rect>
                    <rect id="矩形备份-10" fill="#F1F3FA" x="3.34177215" y="829.5" width="124.316456" height="61"></rect>
                    <rect id="矩形备份-11" fill="#F1F3FA" x="1073" y="1063.5" width="161" height="61"></rect>
                    <rect id="矩形备份-9" fill="#F1F3FA" x="1067" y="572.5" width="161" height="61"></rect>
                    <g id="2022" transform="translate(21.938000, 355.836000)" fill-rule="nonzero">
                      <path d="M9.88,0 C6.954,0 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,0 9.88,0 Z" id="路径" fill="url(#linearGradient-6)"></path>
                      <path d="M32.262,0 C29.07,0 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,0 32.262,0 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-7)"></path>
                      <path d="M55.48,0 C52.554,0 50.198,0.988 48.412,2.964 C46.664,4.826 45.79,7.258 45.752,10.298 L50.198,10.298 C50.274,8.17 50.73,6.536 51.566,5.472 C52.402,4.332 53.656,3.762 55.328,3.762 C56.886,3.762 58.064,4.104 58.862,4.826 C59.622,5.548 60.04,6.65 60.04,8.132 C60.04,9.652 59.432,11.096 58.254,12.426 C57.494,13.224 56.278,14.25 54.53,15.466 C51.376,17.632 49.286,19.304 48.298,20.444 C46.474,22.496 45.6,24.89 45.6,27.664 L64.524,27.664 L64.524,23.75 L51.148,23.75 C51.756,22.382 53.428,20.786 56.202,18.962 C58.862,17.176 60.762,15.732 61.826,14.592 C63.574,12.654 64.486,10.526 64.486,8.17 C64.486,5.738 63.65,3.8 61.978,2.28 C60.268,0.76 58.102,0 55.48,0 Z" id="路径" fill="url(#linearGradient-6)"></path>
                      <path d="M78.28,0 C75.354,0 72.998,0.988 71.212,2.964 C69.464,4.826 68.59,7.258 68.552,10.298 L72.998,10.298 C73.074,8.17 73.53,6.536 74.366,5.472 C75.202,4.332 76.456,3.762 78.128,3.762 C79.686,3.762 80.864,4.104 81.662,4.826 C82.422,5.548 82.84,6.65 82.84,8.132 C82.84,9.652 82.232,11.096 81.054,12.426 C80.294,13.224 79.078,14.25 77.33,15.466 C74.176,17.632 72.086,19.304 71.098,20.444 C69.274,22.496 68.4,24.89 68.4,27.664 L87.324,27.664 L87.324,23.75 L73.948,23.75 C74.556,22.382 76.228,20.786 79.002,18.962 C81.662,17.176 83.562,15.732 84.626,14.592 C86.374,12.654 87.286,10.526 87.286,8.17 C87.286,5.738 86.45,3.8 84.778,2.28 C83.068,0.76 80.902,0 78.28,0 Z" id="路径" fill="url(#linearGradient-6)"></path>
                    </g>
                    <g id="2021" transform="translate(1091.938000, 589.836000)" fill-rule="nonzero">
                      <path d="M9.88,0 C6.954,0 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,0 9.88,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                      <path d="M32.262,0 C29.07,0 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,0 32.262,0 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-2)"></path>
                      <path d="M55.48,0 C52.554,0 50.198,0.988 48.412,2.964 C46.664,4.826 45.79,7.258 45.752,10.298 L50.198,10.298 C50.274,8.17 50.73,6.536 51.566,5.472 C52.402,4.332 53.656,3.762 55.328,3.762 C56.886,3.762 58.064,4.104 58.862,4.826 C59.622,5.548 60.04,6.65 60.04,8.132 C60.04,9.652 59.432,11.096 58.254,12.426 C57.494,13.224 56.278,14.25 54.53,15.466 C51.376,17.632 49.286,19.304 48.298,20.444 C46.474,22.496 45.6,24.89 45.6,27.664 L64.524,27.664 L64.524,23.75 L51.148,23.75 C51.756,22.382 53.428,20.786 56.202,18.962 C58.862,17.176 60.762,15.732 61.826,14.592 C63.574,12.654 64.486,10.526 64.486,8.17 C64.486,5.738 63.65,3.8 61.978,2.28 C60.268,0.76 58.102,0 55.48,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                      <path d="M75.278,0.532 C74.366,1.482 73.226,2.356 71.858,3.192 C70.49,3.952 69.198,4.484 67.982,4.788 L67.982,9.196 C70.49,8.474 72.542,7.372 74.176,5.89 L74.176,27.664 L78.622,27.664 L78.622,0.532 L75.278,0.532 Z" id="路径" fill="url(#linearGradient-8)"></path>
                    </g>
                    <g id="2023" transform="translate(1091.938000, 111.836000)" fill-rule="nonzero">
                      <path d="M9.88,0 C6.954,0 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,0 9.88,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                      <path d="M32.262,0 C29.07,0 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,0 32.262,0 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-2)"></path>
                      <path d="M55.48,0 C52.554,0 50.198,0.988 48.412,2.964 C46.664,4.826 45.79,7.258 45.752,10.298 L50.198,10.298 C50.274,8.17 50.73,6.536 51.566,5.472 C52.402,4.332 53.656,3.762 55.328,3.762 C56.886,3.762 58.064,4.104 58.862,4.826 C59.622,5.548 60.04,6.65 60.04,8.132 C60.04,9.652 59.432,11.096 58.254,12.426 C57.494,13.224 56.278,14.25 54.53,15.466 C51.376,17.632 49.286,19.304 48.298,20.444 C46.474,22.496 45.6,24.89 45.6,27.664 L64.524,27.664 L64.524,23.75 L51.148,23.75 C51.756,22.382 53.428,20.786 56.202,18.962 C58.862,17.176 60.762,15.732 61.826,14.592 C63.574,12.654 64.486,10.526 64.486,8.17 C64.486,5.738 63.65,3.8 61.978,2.28 C60.268,0.76 58.102,0 55.48,0 Z" id="路径" fill="url(#linearGradient-1)"></path>
                      <path d="M78.128,0 C75.468,0 73.264,0.722 71.554,2.242 C69.73,3.762 68.742,5.89 68.514,8.626 L72.884,8.626 C73.036,6.992 73.568,5.776 74.48,4.978 C75.354,4.18 76.57,3.8 78.166,3.8 C79.724,3.8 80.902,4.142 81.7,4.826 C82.422,5.51 82.802,6.498 82.802,7.828 C82.802,9.158 82.384,10.184 81.586,10.868 C80.75,11.552 79.534,11.894 77.938,11.894 L76.038,11.894 L76.038,15.238 L77.938,15.238 C79.686,15.238 81.016,15.58 81.928,16.34 C82.84,17.062 83.334,18.202 83.334,19.76 C83.334,21.052 82.878,22.116 82.004,22.99 C81.016,23.94 79.648,24.434 77.938,24.434 C76.38,24.434 75.126,23.978 74.176,23.104 C73.112,22.154 72.58,20.786 72.504,19.038 L67.982,19.038 C68.21,22.078 69.274,24.434 71.174,26.03 C72.884,27.474 75.164,28.196 77.976,28.196 C80.94,28.196 83.334,27.36 85.158,25.726 C86.906,24.13 87.78,22.078 87.78,19.57 C87.78,17.974 87.324,16.644 86.412,15.618 C85.576,14.63 84.322,13.908 82.726,13.414 C85.728,12.426 87.248,10.45 87.248,7.486 C87.248,5.13 86.412,3.306 84.778,1.976 C83.106,0.646 80.864,0 78.128,0 Z" id="路径" fill="url(#linearGradient-9)"></path>
                    </g>
                    <g id="2019" transform="translate(1091.938000, 1079.836000)" fill-rule="nonzero">
                      <path d="M9.88,-4.54747351e-13 C6.954,-4.54747351e-13 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,-4.54747351e-13 9.88,-4.54747351e-13 Z" id="路径" fill="url(#linearGradient-10)"></path>
                      <path d="M32.262,-4.54747351e-13 C29.07,-4.54747351e-13 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,-4.54747351e-13 32.262,-4.54747351e-13 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-11)"></path>
                      <path d="M52.478,0.532 C51.566,1.482 50.426,2.356 49.058,3.192 C47.69,3.952 46.398,4.484 45.182,4.788 L45.182,9.196 C47.69,8.474 49.742,7.372 51.376,5.89 L51.376,27.664 L55.822,27.664 L55.822,0.532 L52.478,0.532 Z" id="路径" fill="url(#linearGradient-12)"></path>
                      <path d="M70.338,-4.54747351e-13 C67.602,-4.54747351e-13 65.322,0.874 63.498,2.66 C61.674,4.408 60.762,6.65 60.762,9.386 C60.762,12.046 61.522,14.212 63.118,15.884 C64.714,17.632 66.88,18.506 69.54,18.506 C70.908,18.506 72.124,18.24 73.188,17.746 C74.29,17.214 75.24,16.378 76.038,15.276 L76.228,15.276 L76.228,15.618 C76.228,18.126 75.696,20.216 74.67,21.888 C73.568,23.636 72.124,24.548 70.338,24.548 C67.716,24.548 66.158,23.294 65.626,20.786 L61.294,20.786 C61.94,25.726 64.942,28.196 70.3,28.196 C73.416,28.196 75.924,26.828 77.786,24.092 C79.61,21.356 80.56,17.746 80.56,13.338 C80.56,9.158 79.686,5.928 77.938,3.572 C76.19,1.178 73.644,-4.54747351e-13 70.338,-4.54747351e-13 Z M70.49,3.686 C72.01,3.686 73.302,4.18 74.29,5.168 C75.278,6.156 75.772,7.486 75.772,9.234 C75.772,10.982 75.278,12.35 74.366,13.376 C73.416,14.364 72.124,14.896 70.49,14.896 C68.818,14.896 67.526,14.364 66.614,13.376 C65.626,12.388 65.17,11.058 65.17,9.386 C65.17,7.714 65.626,6.346 66.614,5.282 C67.602,4.218 68.894,3.686 70.49,3.686 Z" id="形状" fill="url(#linearGradient-13)"></path>
                    </g>
                    <g id="2018备份" transform="translate(102.938000, 1192.836000)" fill-rule="nonzero">
                      <path d="M9.88,-4.54747351e-13 C6.954,-4.54747351e-13 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,-4.54747351e-13 9.88,-4.54747351e-13 Z" id="路径" fill="url(#linearGradient-1)"></path>
                      <path d="M32.262,-4.54747351e-13 C29.07,-4.54747351e-13 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,-4.54747351e-13 32.262,-4.54747351e-13 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-2)"></path>
                      <path d="M52.478,0.532 C51.566,1.482 50.426,2.356 49.058,3.192 C47.69,3.952 46.398,4.484 45.182,4.788 L45.182,9.196 C47.69,8.474 49.742,7.372 51.376,5.89 L51.376,27.664 L55.822,27.664 L55.822,0.532 L52.478,0.532 Z" id="路径" fill="url(#linearGradient-8)"></path>
                      <path d="M70.642,-4.54747351e-13 C67.716,-4.54747351e-13 65.398,0.76 63.688,2.318 C62.13,3.686 61.37,5.396 61.37,7.448 C61.37,8.816 61.674,9.994 62.282,10.944 C62.928,11.932 63.916,12.73 65.284,13.262 L65.284,13.338 C63.992,13.68 62.928,14.44 62.016,15.618 C60.952,16.91 60.458,18.392 60.458,20.064 C60.458,22.496 61.332,24.434 63.118,25.954 C64.866,27.436 67.374,28.196 70.642,28.196 C73.872,28.196 76.38,27.436 78.166,25.954 C79.914,24.434 80.826,22.496 80.826,20.064 C80.826,18.392 80.294,16.91 79.268,15.618 C78.356,14.44 77.254,13.68 76,13.338 L76,13.262 C77.33,12.73 78.318,11.932 79.002,10.944 C79.61,9.994 79.914,8.816 79.914,7.448 C79.914,5.396 79.116,3.686 77.596,2.318 C75.848,0.76 73.53,-4.54747351e-13 70.642,-4.54747351e-13 Z M70.642,3.534 C72.238,3.534 73.492,3.952 74.48,4.826 C75.316,5.586 75.734,6.498 75.734,7.6 C75.734,8.854 75.392,9.842 74.708,10.564 C73.872,11.438 72.504,11.894 70.642,11.894 C68.742,11.894 67.412,11.438 66.576,10.564 C65.892,9.842 65.55,8.854 65.55,7.6 C65.55,6.498 65.968,5.586 66.804,4.826 C67.754,3.952 69.046,3.534 70.642,3.534 Z M70.642,15.2 C72.542,15.2 73.986,15.656 75.012,16.606 C75.924,17.442 76.418,18.62 76.418,20.064 C76.418,21.432 75.924,22.496 74.974,23.332 C73.91,24.168 72.466,24.624 70.642,24.624 C68.78,24.624 67.374,24.206 66.386,23.37 C65.36,22.534 64.866,21.432 64.866,20.064 C64.866,18.62 65.322,17.442 66.31,16.568 C67.298,15.656 68.742,15.2 70.642,15.2 Z" id="形状" fill="url(#linearGradient-14)"></path>
                    </g>
                    <g id="2020" transform="translate(14.938000, 845.836000)" fill-rule="nonzero">
                      <path d="M9.88,0 C6.954,0 4.598,0.988 2.812,2.964 C1.064,4.826 0.19,7.258 0.152,10.298 L4.598,10.298 C4.674,8.17 5.13,6.536 5.966,5.472 C6.802,4.332 8.056,3.762 9.728,3.762 C11.286,3.762 12.464,4.104 13.262,4.826 C14.022,5.548 14.44,6.65 14.44,8.132 C14.44,9.652 13.832,11.096 12.654,12.426 C11.894,13.224 10.678,14.25 8.93,15.466 C5.776,17.632 3.686,19.304 2.698,20.444 C0.874,22.496 0,24.89 0,27.664 L18.924,27.664 L18.924,23.75 L5.548,23.75 C6.156,22.382 7.828,20.786 10.602,18.962 C13.262,17.176 15.162,15.732 16.226,14.592 C17.974,12.654 18.886,10.526 18.886,8.17 C18.886,5.738 18.05,3.8 16.378,2.28 C14.668,0.76 12.502,0 9.88,0 Z" id="路径" fill="url(#linearGradient-10)"></path>
                      <path d="M32.262,0 C29.07,0 26.6,1.292 24.852,3.952 C23.18,6.46 22.344,9.842 22.344,14.098 C22.344,18.354 23.18,21.736 24.852,24.244 C26.6,26.866 29.07,28.196 32.262,28.196 C35.416,28.196 37.886,26.866 39.672,24.244 C41.344,21.736 42.18,18.354 42.18,14.098 C42.18,9.842 41.344,6.46 39.672,3.952 C37.886,1.292 35.416,0 32.262,0 Z M32.262,3.762 C34.39,3.762 35.91,4.94 36.822,7.372 C37.43,9.006 37.734,11.248 37.734,14.098 C37.734,16.91 37.43,19.152 36.822,20.824 C35.91,23.218 34.39,24.434 32.262,24.434 C30.096,24.434 28.576,23.218 27.702,20.824 C27.094,19.152 26.79,16.91 26.79,14.098 C26.79,11.248 27.094,9.006 27.702,7.372 C28.576,4.94 30.096,3.762 32.262,3.762 Z" id="形状" fill="url(#linearGradient-11)"></path>
                      <path d="M55.48,0 C52.554,0 50.198,0.988 48.412,2.964 C46.664,4.826 45.79,7.258 45.752,10.298 L50.198,10.298 C50.274,8.17 50.73,6.536 51.566,5.472 C52.402,4.332 53.656,3.762 55.328,3.762 C56.886,3.762 58.064,4.104 58.862,4.826 C59.622,5.548 60.04,6.65 60.04,8.132 C60.04,9.652 59.432,11.096 58.254,12.426 C57.494,13.224 56.278,14.25 54.53,15.466 C51.376,17.632 49.286,19.304 48.298,20.444 C46.474,22.496 45.6,24.89 45.6,27.664 L64.524,27.664 L64.524,23.75 L51.148,23.75 C51.756,22.382 53.428,20.786 56.202,18.962 C58.862,17.176 60.762,15.732 61.826,14.592 C63.574,12.654 64.486,10.526 64.486,8.17 C64.486,5.738 63.65,3.8 61.978,2.28 C60.268,0.76 58.102,0 55.48,0 Z" id="路径" fill="url(#linearGradient-10)"></path>
                      <path d="M77.862,0 C74.67,0 72.2,1.292 70.452,3.952 C68.78,6.46 67.944,9.842 67.944,14.098 C67.944,18.354 68.78,21.736 70.452,24.244 C72.2,26.866 74.67,28.196 77.862,28.196 C81.016,28.196 83.486,26.866 85.272,24.244 C86.944,21.736 87.78,18.354 87.78,14.098 C87.78,9.842 86.944,6.46 85.272,3.952 C83.486,1.292 81.016,0 77.862,0 Z M77.862,3.762 C79.99,3.762 81.51,4.94 82.422,7.372 C83.03,9.006 83.334,11.248 83.334,14.098 C83.334,16.91 83.03,19.152 82.422,20.824 C81.51,23.218 79.99,24.434 77.862,24.434 C75.696,24.434 74.176,23.218 73.302,20.824 C72.694,19.152 72.39,16.91 72.39,14.098 C72.39,11.248 72.694,9.006 73.302,7.372 C74.176,4.94 75.696,3.762 77.862,3.762 Z" id="形状" fill="url(#linearGradient-11)"></path>
                    </g>
                  </g>
                  <g id="编组-2" transform="translate(288.000000, 0.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#627FE7" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="12月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="12" y="37">12月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-7" transform="translate(288.000000, 484.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#627FE7" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="10月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="12" y="37">10月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-10" transform="translate(288.000000, 734.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#516FDA" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="05月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="11" y="37">05月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-13" transform="translate(230.000000, 980.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#1C3CAD" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="11月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="12" y="37">11月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-16" transform="translate(300.000000, 1214.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#516FDA" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="10月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="12" y="37">10月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份" transform="translate(582.000000, 0.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#3C5CD0" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="05月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">05月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-8" transform="translate(582.000000, 484.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#3C5CD0" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="06月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">06月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-11" transform="translate(582.000000, 734.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#3C5CD0" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="06月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">06月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-14" transform="translate(554.000000, 980.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#516FDA" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="08月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">08月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-17" transform="translate(617.000000, 1214.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#516FDA" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="11月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">11月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-2" transform="translate(881.000000, 0.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#1C3CAD" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="02月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">02月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-9" transform="translate(881.000000, 484.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#1C3CAD" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="05月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">05月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-12" transform="translate(888.000000, 734.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#1C3CAD" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="10月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">10月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-15" transform="translate(880.000000, 980.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#627FE7" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="05月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">05月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-18" transform="translate(920.000000, 1215.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#627FE7" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="12月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">12月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-3" transform="translate(961.000000, 253.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#1C3CAD" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="09月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">09月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-4" transform="translate(709.000000, 253.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#3C5CD0" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="08月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">08月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-5" transform="translate(444.000000, 253.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#3C5CD0" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="03月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">03月</tspan>
                    </text>
                  </g>
                  <g id="编组-2备份-6" transform="translate(210.000000, 253.000000)">
                    <g id="编组-4备份-4">
                      <circle id="椭圆形" stroke="#516FDA" fill="#FFFFFF" cx="30.5" cy="30.5" r="30.5"></circle>
                      <circle id="椭圆形" fill="#627FE7" cx="30.9013158" cy="30.9013158" r="26.0855263"></circle>
                    </g>
                    <text id="02月" font-family="PingFangSC-Semibold, PingFang SC" font-size="20" font-weight="500" fill="#FFFFFF">
                      <tspan x="10" y="37">02月</tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div class="mainContentItem oneLine item202312">专利形式核查工具上线</div>
        <div class="mainContentItem oneLine item202305">竞争对手监控上线</div>
        <div class="mainContentItem oneLine item202302">智慧园区系统上线</div>
        <div class="mainContentItem oneLine item202209">蓝灯鱼英文网站完成上线</div>
        <div class="mainContentItem oneLine item202208">法律文书及商标对比功能上线</div>
        <div class="mainContentItem oneLine item202203">专利OA翻译上线</div>
        <div class="mainContentItem oneLine item202202">商标公告功能上线</div>
        <div class="mainContentItem oneLine item202110">商标图文检索产品3.0上线</div>
        <div class="mainContentItem item202106">专利AI翻译产品，Microsoft word/excel插件发布</div>
        <div class="mainContentItem item202105">专利AI翻译产品，SDL TradosStudio（2019版）插件发布</div>
        <div class="mainContentItem item202010">专利AI翻译产品，术语一致性功能发布，统一率提升80%</div>
        <div class="mainContentItem item202006">专利检索产品，基于bert神经网络模型语义检索发布</div>
        <div class="mainContentItem item202005">商标官申自动提交工具V2.2稳定版发布</div>
        <div class="mainContentItem item201911">专利AI翻译产品，第二代模型以Transformer基础，中日方向</div>
        <div class="mainContentItem item201908">商标图文检索产品，第二代模型精准率领先行业水平</div>
        <div class="mainContentItem item201905">专利AI翻译产品，第二代模型以Transformer基础，中英方向</div>
        <div class="mainContentItem item201812">商标图文检索产品，第一代模型正式商用</div>
        <div class="mainContentItem item201811">专利AI翻译产品,第一代模型神经机器翻译(NMT),中英方向</div>
        <div class="mainContentItem oneLine item201810">商标文字检索产品，V1.0版本</div>
      </div>

    </div>

    <div class="experience">
      <div class="experience_cen">
        <div class="top">立即体验</div>
        <div class="top_bottom">扫一扫 二维码</div>
        <ul>
          <li>
            <img style="border-radius: 15px" src="../../assets/images/about/sy.png" alt="">
            <span>试用小程序</span>
          </li>
          <li>
            <img src="../../assets/images/about/kf.png" alt="">
            <span>客服二维码</span>
          </li>
        </ul>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能,知识产权,蓝灯鱼招聘,蓝灯鱼地址,关于蓝灯鱼'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是提供商标查询，专利检索，专利翻译服务的知识产权智能检索平台，应用人工智能技术，已发布商标图形查询，专利翻译，CPC官文提取工具，知识产权管理系统等系列产品。'
      }],
    },
    data () {
      return {
        isCN: true,
      }
    },
    mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
    },
    components: {
      footers
    },
    methods: {
      jump(){
        this.$router.push({
          path:'/recruitment'
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;
    .index_banner {
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}about/aboutbanner.png") no-repeat center
        center/auto 100%;
      position: relative;
      .about_banner_title{
        position: absolute;
        top: 94px;
        left: 19%;
        p:nth-child(1){
          font-size: 32px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 45px;
        }
        p:nth-child(2){
          font-size: 24px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 33px;
        }
      }
      .about_banner_cen{
        position: absolute;
        padding: 35px 52px;
        box-sizing: border-box;
        bottom: 41px;
        right: 16%;
        width: 892px;
        height: 260px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        font-size: 20px;
        font-weight: 400;
        color: #6E6B6B;
        line-height: 38px;
        .banner_title{
          position: relative;
          i{
            position: relative;
            z-index: 999999;
          }
          &:before {
            position: absolute;
            content: '';
            width: 283px;
            height: 10px;
            bottom: -2px;
            left: 4px;
            background: rgba(242,221,108,0.62);
          }
        }
      }
      @media screen and (max-width: 1600px) {
        .about_banner_title{
          left: 16%;
        }
        .about_banner_cen {
          right: 13%;
        }
      }

      @media screen and (max-width: 1400px) {
        .about_banner_title{
          left: 13%;
        }
        .about_banner_cen {
          right: 10%;
        }
      }
    }
    .step{
      width: 1202px;
      margin: 0 auto;
      .top {
        position: relative;
        margin: 105px auto 87px;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        position: relative;
        width: 130px;
        height: 46px;

        span {
          position: relative;
          z-index: 9999;
        }

        &:before {
          position: absolute;
          content: '';
          bottom: 3px;
          left: -20px;
          width: 168px;
          height: 12px;
          background: #264DD8;
          border-radius: 6px;
          background: rgba(38, 77, 216, 0.58);
        }
      }
      .littleTitle{
        display: inline-block;
        width: 240px;
        font-weight: bolder;

        position: absolute;
        right: -170px;
        bottom: -26px;
        color: #B1B1B1;
        font-size: 18px;
      }
      .step_cen{
        position: relative;
        img{
          display: block;
          height: 1551px;
          margin: 0 auto;
        }
        .top_year{
          font-size: 20px;
          font-weight: 600;
          color: #FFFFFF;
        }
        .top_li{
          li {
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 22px;
            display: flex;
            /*padding-right: 14px;*/
            box-sizing: border-box;
            width: 566px;
            height: 64px;
            background: #FFFFFF;
            border-radius: 15px;
            font-size: 18px;
            font-weight: 400;
            color: #555555;
            line-height: 25px;
            span{
              display: block;
              width: 64px;
              height: 32px;
              background: #90A4EF;
              border-radius: 18px;
              margin: 0 14px 0 21px;
              text-align: center;
              line-height: 32px;
              font-size: 18px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          &.left{
            /*padding-left: 14px;*/
            /*padding-right: 0;*/
            li{
              width: 567px;
              justify-content: flex-start;
              span{
                margin: 0 9px 0 15px;
              }
            }
          }
        }
        .top_year_item1{
          position: absolute;
          top: 29px;
          left: 580px;
        }
        .top_li_item1{
          position: absolute;
          top: 113px;
          left: -9px;
        }
        .top_year_item2{
          position: absolute;
          top: 402px;
          left: 580px;
        }
        .top_li_item2{
          position: absolute;
          top: 483px;
          left: 630px;
        }
        .top_year_item3{
          position: absolute;
          top: 783px;
          left: 580px;
        }
        .top_li_item3{
          position: absolute;
          top: 865px;
          left: -9px;
        }
        .top_year_item4{
          position: absolute;
          top: 1162px;
          left: 580px;
        }
        .top_li_item4{
          position: absolute;
          top: 1248px;
          left: 630px;
        }
      }
    }
    .about_top {
      width: 1185px;
      height: 350px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin: 0 auto;
      ul{
        width: 924px;
        li{
          font-size: 20px;
          font-weight: 500;
          color: #555555;
          line-height: 38px;
        }
      }
      .top {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        position: relative;
        width: 238px;
        height: 46px;

        span {
          position: relative;
          z-index: 9999;
        }

        &:before {
          position: absolute;
          content: '';
          bottom: 3px;
          left: -20px;
          width: 168px;
          height: 12px;
          background: #264DD8;
          border-radius: 6px;
          background: rgba(38, 77, 216, 0.58);
        }
      }
    }
    .experience{
      margin-top: 62px;
      padding-bottom: 116px;
      box-sizing: border-box;
      width: 100%;
      background: #ffffff;
      .experience_cen{
        width: 1111px;
        margin: 0 auto;
        .top{
          padding-top: 68px;
          margin: 0px auto 0px;
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          position: relative;
          width: 130px;
          height: 46px;
          &:before{
            position: absolute;
            content: '';
            bottom: 0;
            left: -20px;
            width: 168px;
            height: 12px;
            background: #264DD8;
            border-radius: 6px;
            background: rgba(38,77,216,0.58);
          }
        }
        .top_bottom{
          width: 102px;
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin: 10px auto 77px;
        }
        ul{
          display: flex;
          justify-content: space-between;
          li{
            padding: 21px 77px 21px 27px;
            box-sizing: border-box;
            width: 510px;
            height: 238px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            span{
              text-align: center;
              line-height: 56px;
              display: block;
              width: 150px;
              height: 56px;
              background: #516FDA;
              border-radius: 29px;
              font-size: 22px;
              font-weight: 500;
              color: #FCFCFD;
            }
            img{
              width: 196px;
              height: 196px;
              display: block;
            }
            &:nth-of-type(1){
              background: rgba(46,84,217,0.8);
              span{
                background: #FFFFFF;
                color: #5876E1;
              }
            }
          }
        }
      }
    }
  }
  .mainContent{
    position: relative;
    .mainContentItem{
      position: absolute;
      text-align: center;
      padding: 10px 20px;
      background-color: #FFFFFF;
      border-radius: 15px;
      font-size: 16px;
      color: #333333;
      height: 50px;
    }
    .oneLine{
      line-height: 50px;
    }

    .item202312{
      top: 125px;
      left: 210px;
      width: 170px;
    }
    .item202305{
      top: 125px;
      left: 510px;
      width: 170px;
    }
    .item202302{
      top: 125px;
      left: 810px;
      width: 170px;
    }
    .item202209{
      top: 380px;
      left: 880px;
      width: 210px;
    }
    .item202208{
      top: 380px;
      left: 610px;
      width: 210px;
    }
    .item202203{
      top: 380px;
      left: 380px;
      width: 170px;
    }
    .item202202{
      top: 380px;
      left: 110px;
      width: 210px;
    }
    .item202110{
      top: 610px;
      left: 150px;
      width: 250px;
    }
    .item202106{
      top: 610px;
      left: 465px;
      width: 250px;
    }
    .item202105{
      top: 610px;
      left: 780px;
      width: 250px;
    }
    .item202010{
      top: 860px;
      left: 780px;
      width: 250px;
    }
    .item202006{
      top: 860px;
      left: 465px;
      width: 250px;
    }
    .item202005{
      top: 860px;
      left: 150px;
      width: 250px;
    }
    .item201911{
      top: 1110px;
      left: 100px;
      width: 270px;
    }
    .item201908{
      top: 1110px;
      left: 430px;
      width: 270px;
    }
    .item201905{
      top: 1110px;
      left: 760px;
      width: 270px;
    }
    .item201812{
      top: 1340px;
      left: 800px;
      width: 250px;
    }
    .item201811{
      top: 1340px;
      left: 490px;
      width: 250px;
    }
    .item201810{
      top: 1340px;
      left: 180px;
      width: 250px;
    }
  }
</style>
